import diContainer from "true-di";

const StorageService = parent =>
	diContainer({
		priceItem: () => parent.priceItem,
		billingInfo: () => parent.billingInfo,
		cancellationSurvey: () => parent.cancellationSurvey,
		trial: () => parent.trial,
		token: () => parent.token,
		refreshToken: () => parent.refreshToken,

		setTokens: self => ({ token, refreshToken }) => {
			self.token.write(token);
			self.refreshToken.write(refreshToken);
		},

		clearAll: self => () => {
			self.priceItem.clear();
			self.billingInfo.clear();
			self.token.clear();
			self.refreshToken.clear();
			self.cancellationSurvey.clear();
			self.trial.clear();
		},

		clearPurchaseData: self => () => {
			self.priceItem.clear();
			self.billingInfo.clear();
		},
	});

export default StorageService;
