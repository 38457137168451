import React from "react";
import { node } from "prop-types";
import AssetsProvider from "@insightfulscience/atomic-react/utils/AssetsProvider";
import { publicConfig } from "../config";

const { SB_CDN_HOST } = publicConfig;

const baseAssetsUrl = `${SB_CDN_HOST}/assets`;

const AssetsBaseUrlProvider = ({ children }) => (
	<AssetsProvider assetPrefix={baseAssetsUrl}>{children}</AssetsProvider>
);

AssetsBaseUrlProvider.propTypes = {
	children: node,
};

export default AssetsBaseUrlProvider;
