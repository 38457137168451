import cookie from "js-cookie";

const clientCookie = (cookieProvider = cookie) => ({
	read: cookieName => cookieProvider.get()[cookieName],
	write: (cookieName, value, options) => {
		cookieProvider.set(cookieName, value, options);
	},
	remove: cookieName => {
		cookieProvider.remove(cookieName);
	},
});

export default clientCookie;
