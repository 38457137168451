import React from "react";
import Router from "next/router";
import { bool } from "prop-types";
import { ensureFunc, isFunc, isPromise } from "@insightfulscience/shared-utils/fn";

const hooks = {
	mapServerSideRedirect: pathOrUrl => pathOrUrl,
	mapClientSideRedirect: pathOrUrl => pathOrUrl,
};

/** @type {(mapper: (pathOrUrl: string, context: import("next").NextPageContext) => string) => void} */
export const mapServerSideRedirectUrl = mapper => {
	hooks.mapServerSideRedirect = ensureFunc(mapper, pathOrUrl => pathOrUrl);
};

/** @type {(mapper: (pathOrUrl: string, context: import("next").NextPageContext) => string) => void} */
export const mapClientSideRedirectUrl = mapper => {
	hooks.mapClientSideRedirect = ensureFunc(mapper, pathOrUrl => pathOrUrl);
};

const serverReplaceUrl = (dest, context) => {
	const { res } = context;
	res.redirect(302, hooks.mapServerSideRedirect(dest, context));
	res.end();
	return { __dontRender: true };
};

const clientReplaceUrl = (dest, context) => {
	Router.push(hooks.mapServerSideRedirect(dest, context));
	return { __dontRender: true };
};

export const replaceUrl = global.window == null ? serverReplaceUrl : clientReplaceUrl;

const redirect = (dest, context, props) => {
	const redirectHandler = global.window == null ? serverReplaceUrl : clientReplaceUrl;
	return redirectHandler(
		isFunc(dest) ? dest(props || context, props && context) : dest, // calculating dest
		context,
	);
};

const safeApply = (fn, ...args) => (isFunc(fn) ? fn(...args) : { __dontRender: false });

const shouldRender = ({ __dontRender } = {}) => !__dontRender;

const createTransition = ([predicate, redirectTo]) => (...args) =>
	shouldRender(...args) && predicate(...args) ? redirectTo : null;

const composeTransitions = (t1, t2) => (...args) => t1(...args) || t2(...args);

const createTransitions = (predicate, redirectTo) =>
	Array.isArray(predicate)
		? predicate.map(createTransition).reduce(composeTransitions)
		: createTransition([predicate, redirectTo]);

const createRedirectWrapper = (name, Component, getInitialProps) =>
	Object.assign(({ __dontRender, ...props }) => __dontRender || <Component {...props} />, {
		propTypes: {
			__dontRender: bool,
			...Component.propTypes, // eslint-disable-line react/forbid-foreign-prop-types
		},
		displayName: `${name}(${Component.displayName || Component.name})`,
		getInitialProps,
	});

const redirectIf = (predicate, redirectTo) => Component =>
	createRedirectWrapper("withRedirect", Component, context => {
		const transition = createTransitions(predicate, redirectTo);
		const dest = transition(context);
		return dest ? redirect(dest, context) : safeApply(Component.getInitialProps, context);
	});

redirectIf.afterPropsResolved = (predicate, redirectTo) => Component =>
	createRedirectWrapper("withRedirectAfterPropsResolved", Component, context => {
		const transition = createTransitions(predicate, redirectTo);

		const applyRedirect = props => {
			const dest = transition(props, context);
			return dest // check redirect condition
				? redirect(dest, context, props)
				: props;
		};

		const componentProps = safeApply(Component.getInitialProps, context);

		return isPromise(componentProps)
			? componentProps.then(applyRedirect)
			: applyRedirect(componentProps);
	});

redirectIf.onCatch = (predicate, redirectTo) => Component =>
	createRedirectWrapper("withRedirectOnCatch", Component, context => {
		const transition = createTransitions(predicate, redirectTo);

		const applyRedirect = err => {
			const dest = transition(err, context);
			if (!dest) throw err;
			return redirect(dest, context, err);
		};

		try {
			const componentProps = safeApply(Component.getInitialProps, context);
			return isPromise(componentProps) ? componentProps.catch(applyRedirect) : componentProps;
		} catch (err) {
			return applyRedirect(err);
		}
	});

export default redirectIf;
