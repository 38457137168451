/* globals WEBPACK_GRAPHQL_URI */
export const IS_SERVER = typeof window === "undefined";
export const IS_BROWSER = !IS_SERVER;

export const publicConfig = {
	// Build Time Configuration it will be boundeled to client and server
	ENV: process.env.ENV,
	// NODE_ENV: process.env.NODE_ENV,
	// PORT: process.env.PORT,
	DOWNLOAD_DOMAIN: process.env.DOWNLOAD_DOMAIN,
	STATIC_SITE_DOMAIN: process.env.STATIC_SITE_DOMAIN,
	GRAPHQL_URI: WEBPACK_GRAPHQL_URI,
	GRAPHQL_HEALTH_URL: process.env.GRAPHQL_HEALTH_URL,
	SB_CDN_HOST: process.env.SB_CDN_HOST,
	// AWS_S3: process.env.AWS_S3,
	AWS_S3_PUBLIC_HOST: process.env.AWS_S3_PUBLIC_HOST,
	BUILD_NUMBER: process.env.BUILD_NUMBER,
	RELEASE: process.env.RELEASE,
	CI: process.env.CI,
	DEBUG_MODE: process.env.DEBUG_MODE,
	VWO_ID: process.env.VWO_ID,
	GTM_ENV_NAME: process.env.GTM_ENV_NAME,
	RECURLY_PUBLIC_KEY: process.env.RECURLY_PUBLIC_KEY,
	SENTRY_DSN: process.env.SENTRY_DSN,
	SENTRY_TRACING_RATE: process.env.SENTRY_TRACING_RATE,
	HUBSPOT_PORTAL_ID: process.env.HUBSPOT_PORTAL_ID,
	HUBSPOT_IGEM_FORM_ID: process.env.HUBSPOT_IGEM_FORM_ID,
	HUBSPOT_FREE_COURSE_LICENSE_FORM_ID: process.env.HUBSPOT_FREE_COURSE_LICENSE_FORM_ID,
	HUBSPOT_SCHOOL_PETITION_FORM_ID: process.env.HUBSPOT_SCHOOL_PETITION_FORM_ID,
	HUBSPOT_VIEWER_FORM_ID: process.env.HUBSPOT_VIEWER_FORM_ID,
	CTF_ENV: process.env.CTF_ENV,
	CTF_SPACE: process.env.CTF_SPACE,
	CTF_TOKEN: process.env.CTF_TOKEN,
	PLANS: process.env.PLANS,
	GRAPHQL_NAMESPACE: process.env.GRAPHQL_NAMESPACE, // depricated
	COOKIE_NAME: process.env.COOKIE_NAME,
	TOKEN_COOKIE_EXP_DAYS: process.env.TOKEN_COOKIE_EXP_DAYS,
	STAY_SIGNED_AFTER_SIGN_UP: process.env.STAY_SIGNED_AFTER_SIGN_UP,
	GOOGLE_RECAPTCHA_SITE_KEY: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
	CASTLE_PUBLIC_KEY: process.env.CASTLE_PUBLIC_KEY,
	VERSION_NODE: process.env.VERSION_NODE,
	VERSION_NPM: process.env.VERSION_NPM,
	SG_API_BASE_URL: process.env.SG_API_BASE_URL,
};
