import { stringify, parse } from "@insightfulscience/shared-utils/parsers/json";

export const createItemStorage = (
	storage,
	keyName,
	defOptions,
	serialize = stringify,
	deserialize = parse,
) => ({
	write: (value, options) =>
		storage.write(keyName, serialize(value), { ...defOptions, ...options }),
	read: () => deserialize(storage.read(keyName)),
	clear: () => storage.remove(keyName),
});
