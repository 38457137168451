import NProgress from "nprogress";
import { isPromise, idX, isFunc } from "@insightfulscience/shared-utils/fn";

NProgress.configure({ showSpinner: false });

const tasks = new Set();
let started = false;

const manage = () => {
	if (tasks.size > 0 && !started) {
		started = true;
		NProgress.start();
		return;
	}

	if (tasks.size === 0 && started) {
		NProgress.done();
		started = false;
	}
};

export const start = (task = 0) => {
	if (tasks.has(task)) return;
	tasks.add(task);
	manage();
};

export const end = (task = 0) => {
	if (!tasks.has(task)) return;
	tasks.delete(task);
	manage();
};

export const withLoading = task => {
	if (global.window == null) return idX;

	return fn => (...args) => {
		const result = fn(...args);
		if (!isPromise(result)) return result;

		const taskName = typeof task === "function" ? task(...args) : task;

		start(taskName);

		return result.finally(() => end(taskName));
	};
};

export const withLoadingHoC = task => {
	if (global.window == null) return idX;

	return Component => {
		// eslint-disable-next-line no-param-reassign
		Component.getInitialProps = isFunc(Component.getInitialProps)
			? withLoading(task)(Component.getInitialProps)
			: Component.getInitialProps;

		return Component;
	};
};
