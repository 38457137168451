import QS from "querystring";

const search = queryString => queryString && `?${queryString}`;

export const buildQuery = params => search(QS.stringify(params));

export const ref = path =>
	Object.defineProperties(params => `${path}${buildQuery(params)}`, {
		name: {
			value: `ref(${path})`,
		},
		toString: {
			value: () => path,
		},
		path: {
			value: path,
		},
	});

export const makeRefs = routes =>
	typeof routes === "object" && routes !== null
		? Object.assign(
				{},
				...Object.entries(routes).map(([name, path]) => ({
					[name]: makeRefs(path),
				})),
		  )
		: ref(routes);
